(function ($) {
  jQuery(document).ready(function () {
    $(".banner-carousel").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      fade: true,
      cssEase: "linear",
      prevArrow: $(".slick-prev"),
      nextArrow: $(".slick-next"),
    });
  });
})(jQuery);
