(function($) {
  jQuery(document).ready(function() {
    let currentPage = 1;

    $("#load-more").on("click", function() {
      currentPage++; // Do currentPage + 1, because we want to load the next page

      ThemeJS.Functions.send_ajax_request({
        data: {
          action: "news_load_more",
          paged: currentPage,
        },
        success: (response) => {
          console.log(response);
          if (currentPage >= response.max) {
            $("#load-more").hide();
          }
          $(".news-archive-cards").append(response.html);
        },
      });
    });
  });
})(jQuery);
