(function($) {
  jQuery(document).ready(function() {
    $(".content_list_button").click(function() {
      if ($(this).next(".content_list_description").hasClass("show-text")) {
        $(this).next(".content_list_description").removeClass("show-text");
        $(this).find("i").addClass("fa-plus-circle");
        $(this).find("i").removeClass("fa-minus-circle");
        $(this).parent().removeClass("active");
        $(this).attr("aria-expanded", false);
      } else {
        $(this).next(".content_list_description").addClass("show-text");
        $(this).find("i").removeClass("fa-plus-circle");
        $(this).find("i").addClass("fa-minus-circle");
        $(this).parent().addClass("active");
        $(this).attr("aria-expanded", true);
      }
    });

    $(".locations-section .title-content button").click(function() {
      if ($(this).parent().next(".locations-cards").hasClass("show")) {
        $(this).parent().next(".locations-cards").removeClass("show");
        $(this).find("i").addClass("fa-plus-circle");
        $(this).find("i").removeClass("fa-minus-circle");
        $(this).parent().removeClass("active");
        $(this).attr("aria-expanded", false);
      } else {
        $(this).parent().next(".locations-cards").addClass("show");
        $(this).find("i").removeClass("fa-plus-circle");
        $(this).find("i").addClass("fa-minus-circle");
        $(this).parent().addClass("active");
        $(this).attr("aria-expanded", true);
      }
    });
  });
})(jQuery);
